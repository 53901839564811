<template>
  <AppHeader :dir="rtl ? 'rtl' : 'ltr'">
    <template #prefix>
      <CourseLink
        :course="courseStore.course.id"
        :caption="courseStore.course.caption"/>
    </template>
  </AppHeader>
  <ProjectIntroduction
    v-show="showIntro"
    :message="introMessage"
    @close="showIntro = false"/>
  <PreviewLesson v-if="slideshow.id" :id="slideshow.id" :title="menuTitle" />
</template>

<script setup lang="ts">
import { PreviewLesson, createPresentation } from '@nwire/amit-design-system';
import { ref, watchEffect, onMounted, computed, watch, Ref } from 'vue';
import { useStorage } from '@vueuse/core';
import { get, isEmpty } from 'lodash';
import {
  fetchProject,
  fetchStage,
  fetchProjectEnrollments,
  submitProject,
  publishSubmission,
} from '/@/services/projects';
import { fetchGroup } from '/@/services/groups';
import { useStateMapper } from '/@/composables/useStateMapper';
import { useState } from '/@/composables/useState';
import { useRoute, useRouter } from 'vue-router';
import { useAccountStore } from '/@/store/account';
import { useCourseStore } from '/@/store/course';
import AppHeader from '/@/layouts/AppHeader.vue';
import ProjectIntroduction from '/@/components/ProjectIntroduction.vue';
import CourseLink from '/@/components/CourseLink.vue';
import swal from 'sweetalert';

interface IOutline {
  id: string;
  title: string;
  selected: boolean;
  locked: boolean;
}

const accountStore = useAccountStore();
const courseStore = useCourseStore();
const route = useRoute();
const router = useRouter();
const slideshow = ref({
  id: '',
  project: '',
  position: 0,
  title: '',
  display: {
    rtl: true,
  },
  sections: [],
});

const stageState: any = {};
const localState = useStorage(accountStore.user.id, {});

const currentProject = ref();
const locale = ref();
const tracks = ref([]);
const history: any = ref([]);
const showIntro = ref(false);
const outline: Ref<IOutline[]> = ref([]);
const group = ref();
const projectEnrollment = ref();
const stageSummary = computed(() => {
  const summary = get(projectEnrollment.value, 'summary', []);
  return summary.find((item: any) => item.stage.id === route.params.stage);
});
const rtl = computed(() => !!slideshow.value?.display?.rtl);
const course = computed(() => route.query.course as string);

const menuTitle = computed(() =>
  slideshow.value.position
    ? `שלב ${slideshow.value.position} - ${slideshow.value.title}`
    : '',
);

const introMessage = computed(() => {
  if (!projectEnrollment.value || !group.value?.assessments) {
    return '';
  }

  const assessment = group.value.assessments.find(
    item => item.reference === projectEnrollment.value.project,
  );
  return assessment?.introduction ?? '';
});

const { toState, toContent } = useStateMapper();
const { getDisabled } = useState({
  tracks,
});

const { onOutlineChange, onAction, onReady, onSlide, onNext, start, onEmit } =
  createPresentation({
    slideshow,
    outline,
    immediate: false,
    history,
    locale,
  });

watch(
  () => stageSummary.value,
  () => {
    if (!stageSummary.value) {
      return;
    }

    redirectLockedStage();
    initializeStageState();
  },
);

const redirectLockedStage = () => {
  if (stageSummary.value.locked) {
    router.push({
      name: 'student-dashboard',
    });
  }
};

const initializeStageState = () => {
  stageSummary.value.records.forEach(record => {
    stageState[record.question] = record?.answer;
  });
};

const setGroup = () => {
  if (!projectEnrollment.value) {
    return;
  }

  const { onSuccess } = fetchGroup(projectEnrollment.value.group);
  onSuccess(({ data }) => {
    group.value = data;
  });
};

onMounted(() => {
  const { onSuccess } = fetchProjectEnrollments(
    route.params.project,
    accountStore.user.id,
  );
  onSuccess(({ data }) => {
    if (data?.length) {
      projectEnrollment.value = data[0];
      setGroup();
    }
  });
});

watchEffect(() => {
  if (currentProject.value && projectEnrollment.value) {
    start();
  }
});

onOutlineChange(stage =>
  router.push({
    name: 'skill-project',
    params: {
      stage: stage.id,
      project: route.params.project,
    },
    query: { course: course.value },
  }),
);

watchEffect(() => {
  if (!currentProject.value || !projectEnrollment.value) return;
  outline.value = currentProject.value.outline.map(item => ({
    id: item.id,
    title: item.position,
    selected: item.id === route.params.stage,
    locked: projectEnrollment.value.summary?.find(
      ({ stage }) => stage.id === item.id,
    )?.locked,
  }));
});

watch(
  () => route.params.stage,
  () => window.location.reload(),
);

const setHistory = () => {
  const firstPage = get(slideshow.value, 'sections[0]pages[0].id', null);
  if (firstPage) {
    history.value.push(firstPage);
  }
};

watchEffect(() => {
  if (!slideshow.value || !stageSummary.value) {
    return;
  }

  const firstStage = slideshow.value.position === 1;
  const firstVisit = !stageSummary.value.records?.length;
  showIntro.value = firstStage && firstVisit && introMessage.value;
});

const fetchContent = (projectId, stageId) => {
  const { onSuccess: onProjectFetched } = fetchProject(projectId);
  onProjectFetched(({ data: project }) => {
    currentProject.value = project;
    locale.value = project?.locale;
  });

  const { onSuccess: onStageFetched } = fetchStage(projectId, stageId);
  onStageFetched(({ data: stage }) => {
    slideshow.value = stage;
    setHistory();
  });
};
watchEffect(() => fetchContent(route.params.project, route.params.stage));

const continueSaving = async () => {
  if (stageSummary.value.status === 'in_progress') {
    return true;
  }
  if (stageSummary.value.status === 'awaiting_review') {
    return false;
  }

  let currentSummaryIndex = projectEnrollment.value.summary.indexOf(
    stageSummary.value,
  );
  let nextStage =
    currentSummaryIndex < projectEnrollment.value.summary.length - 1
      ? projectEnrollment.value.summary[currentSummaryIndex + 1]
      : null;
  if (nextStage?.records?.length) {
    return false;
  }

  const confirm = await swal({
    text: 'האם ברצונך לשמור את השינויים ולהגיש למורה?',
    buttons: ['לא', 'כן'],
  });
  return confirm;
};

const submitData = async (contextBlocks, contextState) => {
  const questionBlocks = contextBlocks.filter(
    block => block.type === 'question',
  );
  if (!questionBlocks.length) {
    return;
  }
  const canContinue = await continueSaving();
  if (!canContinue) {
    return;
  }

  const records: any = [];
  for (let question of questionBlocks) {
    stageState[question.id] = contextState[question.id];
    records.push({
      question: question.id,
      answer: contextState[question.id],
    });
  }

  let data = {
    project: route.params.project,
    stage: route.params.stage,
    student: accountStore.user.id,
    records,
  };
  submitProject(data);
};

const getPageState = (blocks, blocksState, localState) => {
  const state = {};
  for (let block of blocks) {
    const blockState = blocksState[block.id] || localState?.[block.id];
    state[block.id] = toState(block, blockState);
  }
  return state;
};

const getPreviousAnswer = question => {
  if (Object.prototype.hasOwnProperty.call(stageState, question)) {
    return stageState[question];
  }

  const summary = get(projectEnrollment.value, 'summary', []);
  const allStagesRecords = summary.flatMap(item => item.records);
  const record = allStagesRecords.find(record => record.question === question);
  return record?.answer;
};

const setPageContent = blocks => {
  for (let block of blocks) {
    const question = get(block, 'settings.from.question', null);
    if (!question) {
      continue;
    }

    const submissions = getPreviousAnswer(question);
    const content = toContent({ block, submissions });
    for (const [key, value] of Object.entries(content)) {
      block.content[key] = value;
    }
  }
};

onAction(async ({ context, event }) => {
  if (!context) return;

  const pageId = context.getId();
  const contextState = context.state();
  const contextBlocks = context.flatBlocks();
  if (isEmpty(contextState)) {
    return;
  }

  if (event.value) {
    context.updateState(event.block.id, event.value);
    if (!localState.value[pageId]) {
      localState.value[pageId] = {};
    }
    localState.value[pageId][event.block.id] = event.value;
  }

  const { disabled } = getDisabled(contextBlocks, contextState);
  context.setDisabled(disabled);
});

onEmit(async ({ event, context }) => {
  if (
    [
      'custom_video:video-next-enabled',
      'persona_selection:video-next-enabled',
      'audio_listener:audio-next-enabled',
    ].includes(event.name)
  ) {
    const contextState = context.state();
    const contextBlocks = context.flatBlocks();
    let block = contextBlocks?.find(block =>
      ['persona_selection', 'custom_video', 'audio_listener'].includes(
        block.name,
      ),
    );
    block.watched = true;
    const { disabled } = getDisabled(contextBlocks, contextState);

    context.setDisabled(disabled);
  }
});

const handleSlide = context => {
  const pageId = context.getId();
  const contextBlocks = context.flatBlocks();
  const stateBlocks = contextBlocks.filter(
    block => block.type === 'question' || block.settings?.trackSelection,
  );
  const state = getPageState(stateBlocks, stageState, localState.value[pageId]);
  context.setState(state);

  setPageContent(contextBlocks);

  const { disabled } = getDisabled(contextBlocks, state);
  context.setDisabled(disabled);
};

onReady(({ context }) => {
  handleSlide(context);
});

onSlide(({ context }) => {
  handleSlide(context);
});

onNext(async context => {
  const pageId = context.getId();
  const contextState = context.state();
  const contextBlocks = context.flatBlocks();

  context.setLoading(true);
  delete localState.value[pageId];
  await submitData(contextBlocks, contextState);

  let nextSlide = context.sync({ instructions: null });
  if (!nextSlide) {
    publishSubmission(
      route.params.project as string,
      projectEnrollment.value.id,
      route.params.stage as string,
    );
    await router.push({
      name: 'student-dashboard',
    });
    return;
  }

  if (!history.value.includes(nextSlide.id)) {
    const currentSlideIndex = history.value.indexOf(context.getId());
    history.value.splice(currentSlideIndex + 1, 0, nextSlide.id);
  }

  const nextState = getPageState(
    nextSlide.blocks,
    stageState,
    localState.value[pageId],
  );
  const { disabled } = getDisabled(nextSlide.blocks, nextState);

  context.setLoading(false);

  context.next({
    state: nextState,
    disabled,
  });
});
</script>
